import { Context } from '@nuxt/types'
import { FormatPlugin } from '~/store/modules/format/interfaces'

export default function getPluginApi () {
  const ctx: Context = this
  const api: FormatPlugin = {
    site (prop: any) {
      return prop.siteId ? ctx.app.$sites.siteById(prop.siteId)?.name || `${prop.siteId}` : ''
    },
    sites (prop: any) {
      const siteNames: any = []
      prop.siteId.forEach((item: any) => {
        siteNames.push({ name: ctx.app.$sites.siteById(item)?.name })
      })
      return siteNames
    },

    priceRole (prop: any) {
      return prop.role ? ctx.app.$priceRoles.priceRoleByName(prop.role)?.title || `${prop.role}` : ''
    },

    formParams (prop: any) {
      const formParamTypeId = ctx.app.$formParams.formParamById(prop.id)?.formParamTypeId
      if (!formParamTypeId) { return '' }
      return ctx.app.$formParams.formParamTypeById(formParamTypeId)?.name || `${formParamTypeId}`
    },

    emailTemplates (prop: any) {
      return prop.templateId ? ctx.app.$emailTemplates.templateById(prop.templateId)?.name || `${prop.templateId}` : ''
    },

    createdAtDate (prop: any) {
      if (prop.createdAt === null || prop.createdAt === undefined) { return '' }
      const date = new Date(prop.createdAt * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    offerChangeDate (prop: any) {
      if (prop === null) { return '' }
      const date = new Date(prop * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    updatedAtDate (prop: any) {
      if (prop.updatedAt === null || prop.updatedAt === undefined) { return '' }
      const date = new Date(prop.updatedAt * 1000)
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }).format(date)
    },

    price (prop: any, params?: { round?: 'up' | 'down', nosign?: boolean }) {
      let price
      switch (params?.round) {
        case 'up':
          price = Math.ceil(prop.price)
          break
        case 'down':
          price = Math.floor(prop.price)
          break
        default:
          price = prop.price
          break
      }
      return new Intl.NumberFormat('ru-RU', params?.nosign ? {} : { style: 'currency', currency: ctx.app.$currencies.currencyById(prop.currencyId)?.sign || 'RUB', minimumFractionDigits: 0 }).format(price)
    },

    priceOld (prop: any, params?: { round?: 'up' | 'down', nosign?: boolean }) {
      let price
      switch (params?.round) {
        case 'up':
          price = Math.ceil(prop)
          break
        case 'down':
          price = Math.floor(prop)
          break
        default:
          price = prop
          break
      }
      return new Intl.NumberFormat('ru-RU', params?.nosign ? {} : { style: 'currency', currency: ctx.app.$currencies.currencyById(prop.currencyId)?.sign || 'RUB', minimumFractionDigits: 0 }).format(price)
    },

    date (prop: number) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
      return Intl.DateTimeFormat('ru-RU', options).format(new Date((prop ?? 0)).valueOf())
    },

    role (prop: string) {
      return ctx.app.$roles.roleByName(prop)?.description ?? prop
    },

    currency (prop: any) {
      if (!prop?.currencyId) { return '' }
      return ctx.app.$currencies.currencyById(prop.currencyId)?.name ?? prop.currencyId
    }
  }
  return api
}
