export const feedsItems = [
  {
    value: 'Список фидов',
    link: 'feeds-list',
    code: 'feed.list'
  }
]
export const shopItems = [
  {
    value: 'Товары',
    link: 'products',
    code: 'shop.products.list'
  },
  {
    value: 'Справочники',
    link: 'options',
    code: 'shop.options.list'
  },
  {
    value: 'Заказы',
    link: 'orders',
    code: 'shop.orders.list'
  },
  {
    value: 'Список пользователей',
    link: 'users',
    code: 'core.users.list'
  },
  {
    value: 'Категории',
    link: 'categories',
    code: 'shop.categories.list'
  },
  {
    value: 'Стикеры',
    link: 'stickers',
    code: 'shop.stickers.list'
  },
  {
    value: 'Корзины',
    link: 'carts',
    code: 'shop.basket.list'
  },
  {
    value: 'Группы товаров',
    link: 'product-groups',
    code: 'shop.product_groups.list'
  },
  {
    value: 'Группы скидок',
    link: 'discount-groups',
    code: 'discounts.discount_groups.list'
  },
  {
    value: 'Группы доставок',
    link: 'delivery-groups',
    code: 'delivery.groups.list'
  },
  {
    value: 'Бонусы',
    link: 'bonuses',
    code: 'bonus.bonus.list'
  },
  {
    value: 'Бренды',
    link: 'partners',
    code: 'shop.partners.list'
  },
  {
    value: 'Промокоды',
    link: 'promocodes',
    code: 'shop.promo_codes.list'
  },
  {
    value: 'Произвольные фильтры',
    link: 'custom-filters',
    code: 'shop.custom_filters.list'
  },
  {
    value: 'Счета',
    link: 'bills',
    code: ''
  },
  {
    value: 'Файлы выгрузки',
    link: 'upload-files',
    code: ''
  }
]
export const settingsItems = [
  {
    value: 'Конфиг (для разработчиков)',
    link: 'dev-config',
    code: 'core.config.system'
  },
  {
    value: 'Настройки сайта',
    link: 'site-config',
    code: 'core.config.list'
  },
  {
    value: 'Валюты',
    link: 'currencies',
    code: 'core.currencies.list'
  },
  {
    value: 'Сайты',
    link: 'sites',
    code: 'core.sites.list'
  },
  {
    value: 'Ценовые роли',
    link: 'price-roles',
    code: 'shop.price_roles.list'
  },
  {
    value: 'Роли',
    link: 'roles',
    code: 'core.roles.list'
  },
  {
    value: 'Типы пользователей',
    link: 'user-types',
    code: 'user_type.list'
  },
  {
    value: 'Статусы заказов',
    link: 'status-orders',
    code: 'shop.order_status_group.list'
  },
  {
    value: 'Регионы',
    link: 'regions',
    code: 'points.regions.list'
  },
  {
    value: 'Магазины на карте',
    link: 'shops',
    code: 'points.points.list'
  },
  {
    value: 'Способы оплаты',
    link: 'payments',
    code: 'payments.payment_types.list'
  },
  {
    value: 'Дилеры',
    link: 'dealers',
    code: 'points.points.list'
  },
  {
    value: 'Сотрудники',
    link: 'personnel',
    code: 'core.config.view'
  },
  {
    value: 'Настройки почты',
    link: 'email-config',
    code: 'core.config.view'
  },
  {
    value: 'Настройки талона',
    link: 'settings-ticket',
    code: 'core.config.view'
  }
]

export const seoItems = [
  {
    value: 'Поисковая оптимизация',
    link: 'pages',
    code: 'seo.pages.list'
  },
  {
    value: 'Поисковые запросы',
    link: 'search-logs',
    code: 'search_log.list'
  },
  {
    value: 'Шаблоны',
    link: 'templates',
    code: 'seo.template.list'
  },
  {
    value: 'Склонение слов',
    link: 'wordcase',
    code: 'seo.word_case.list'
  },
  {
    value: 'Склонение слов по числу',
    link: 'wordplural',
    code: 'seo.word_plural.list'
  },
  {
    value: 'Склонение слов по родам',
    link: 'wordadjective',
    code: 'seo.word_adjective.list'
  },
  {
    value: 'Замена слов',
    link: 'wordreplace',
    code: 'seo.word_replace.list'
  },
  {
    value: 'Редактировать Robots.txt',
    link: 'robots',
    code: 'seo.robots.list'
  }
]
export const reviewItems = [
  {
    value: 'Список отзывов',
    link: 'list',
    code: 'reviews.list'
  },
  {
    value: 'Типы отзывов',
    link: 'type',
    code: 'reviews.list'
  },
  {
    value: 'Типы параметров отзывов',
    link: 'param-type',
    code: 'reviews.list'
  }
]
export const formItems = [
  {
    value: 'Список форм',
    link: 'list',
    code: 'form.list'
  },
  {
    value: 'Типы формы',
    link: 'type',
    code: 'form_type.list'
  },
  {
    value: 'Типы параметров формы',
    link: 'param-type',
    code: 'form_type.list'
  }
]
export const sendingItems = [
  {
    value: 'Группы шаблонов',
    link: 'email-template-groups',
    code: 'email_template.groups'
  },
  {
    value: 'Шаблоны писем',
    link: 'email-templates',
    code: 'email_templates.list'
  },
  {
    value: 'Подписчики',
    link: 'subscribers',
    code: 'mass_mailing.list'
  }
]

export const menuItems = [
  {
    item: {
      value: 'Настройки',
      icon: 'el-icon-setting',
      link: 'settings'
    },
    subItems: settingsItems
  },
  {
    item: {
      value: 'Магазин',
      icon: 'el-icon-goods',
      link: 'shop'
    },
    subItems: shopItems
  },
  {
    item: {
      value: 'SEO',
      icon: 'el-icon-discover',
      link: 'seo'
    },
    subItems: seoItems
  },
  {
    item: {
      value: 'Отзывы',
      icon: 'el-icon-chat-line-square',
      link: 'review'
    },
    subItems: reviewItems
  },
  {
    item: {
      value: 'Формы обратной связи',
      icon: 'el-icon-service',
      link: 'form'
    },
    subItems: formItems
  },
  {
    item: {
      value: 'Фиды',
      icon: 'el-icon-document-copy',
      link: 'feeds'
    },
    subItems: feedsItems
  },
  {
    item: {
      value: 'Рассылки',
      icon: 'el-icon-message',
      link: 'sending'
    },
    subItems: sendingItems
  },
  {
    item: {
      value: 'Контент',
      icon: 'el-icon-picture-outline',
      link: 'banner-block'
    },
    code: 'banner_blocks'
  },
  {
    item: {
      value: 'Виджеты',
      icon: 'el-icon-film',
      link: 'widgets'
    },
    code: 'shop.product_widgets.list'
  },
  {
    item: {
      value: 'Лист ожидания',
      icon: 'el-icon-bell',
      link: 'list-waiting'
    },
    code: 'shop.shop_offer_notify.list'
  },
  {
    item: {
      value: 'Новости',
      icon: 'el-icon-notebook-2',
      link: 'news'
    },
    code: 'news'
  },
  {
    item: {
      value: 'Статические страницы',
      icon: 'el-icon-document',
      link: 'pages'
    },
    code: 'shop.pages'
  },
  {
    item: {
      value: 'Синонимы для поиска',
      icon: 'el-icon-search',
      link: 'search-text-replace'
    },
    code: 'search_text_replace.list'
  },
  {
    item: {
      value: 'Коллекции',
      icon: 'el-icon-collection',
      link: 'collections'
    },
    code: 'shop.collections'
  },
  {
    item: {
      value: 'Розыгрыши',
      icon: 'el-icon-present',
      link: 'raffles'
    },
    code: ''
  }
]
