import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { StaticPagesParams, Page } from './interfaces'
import { defaultData, FormError, ValidatorParams, ResponseData } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'pages',
  stateFactory: true,
  namespaced: true
})

export default class PagesModule extends VuexModule {
  /**
   * * Массив всех category
   */
  pagesList: ResponseData<Page> = defaultData

  /**
   * * Текущая category
   */
  page: Page = {
    page: 0,
    parentId: null,
    siteId: [],
    code: '',
    title: '',
    fileId: null,
    fileName: '',
    text: '',
    hasChildren: false,
    hasNotChildren: true
  }

  // ? ______________ getters ______________
  /**
   * * Получить массив pages
   */
  get pages (): ResponseData<Page> {
    return this.pagesList
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите название способа', trigger: ['blur'] }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur', 'change'] }],
      page: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur', 'change'] }]
    }
  }

  /**
   * * Получить page из массива pages
   */
  get pageById () {
    const pages = this.pages
    return function (id: number): Page | undefined {
      return pages.data.find(page => page.id === id)
    }
  }

  /**
   * * Получить текущую page
   */
  get currentPage () {
    return this.page
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!(this.page.title)
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив pages
   * @param pages массив pages
   */

  @Mutation
  setPagesList (pages: ResponseData<Page>) {
    this.pagesList = pages
  }

  /**
   * * Установить currentPage
   * @param page текущая page
   */
  @Mutation
  setCurrentPage (page: Page) {
    this.page = page
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentPage () {
    this.page = {
      page: 0,
      parentId: null,
      siteId: [],
      code: '',
      title: '',
      fileId: null,
      fileName: '',
      text: '',
      hasChildren: false,
      hasNotChildren: true
    }
  }

  @Mutation
  resetPages () {
    this.pagesList = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список pages
   */
  @Action({
    rawError: true
  })
  async getPages (pageParams: StaticPagesParams | null) {
    try {
      const { data } = await $axios.get('/shop/pages', { params: pageParams })
      const response: ResponseData<Page> = { ...data, data: data.data.map((page: Page) => ({ ...page, hasNotChildren: !page.hasChildren })) }
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить page по id
   * @param id id page, который мы хотим получить
   */
  @Action({
    rawError: true
  })
  async getPage (id: number): Promise<Page> {
    try {
      const { data: { data } } = await $axios.get(`/shop/pages/${id}`)
      const response: Page = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить page
   */
  @Action({
    rawError: true
  })
  async editPage () {
    try {
      const { id, ...page } = this.currentPage
      const { data: { data } } = await $axios.put(`/shop/pages/${id}`, page)
      const response: Page = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать page
   */
  @Action({
    rawError: true
  })
  async createPage () {
    try {
      const { data: { data } } = await $axios.post('/shop/pages', this.currentPage)
      const response: Page = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить page по id
   * @param id id page, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removePage (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/pages/${id}`)
      const response: Page = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Привязка ролей к дочерним страницам
   * @param api алиас применяемого свойства
   * @param id id родительской страницы
   */
  @Action({
    rawError: true
  })
  async updateChildren ({ api, id } : { api : 'sites' | 'roles', id: number }) {
    try {
      await $axios.post(`/shop/pages/${id}/update-children-${api}`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
