/**
 * * Упрощенная категория товаров
 * @property { number } id id категории
 * @property { number | null } parentId id родительской категории
 * @property { string } название категории
 * @property { children } список подкатегорий
 * @property { hasNotChildren } флаг отсутствия подкатегорий
 */
interface Category {
  id: number;
  parentId: number | null;
  title: String;
  children?: Category[];
  hasNotChildren?: boolean
}

/**
 * * Утилита восстановления дерева категорий из массива
 * @param categories массив категорий
 * @returns дерево категорий
 */
export default function (categories: Category[]) {
  // * Дерево категорий
  const tree: Category[] = []
  // * Карта категорий
  const map: { [key: number]: Category } = {}

  // * Созданий карты категорий
  categories.forEach((c: Category) => {
    // * Пропуск категории "Все товары"
    if (c.id === -42) {
      return
    }
    // * Запись категории в карту
    map[c.id] = c
    // * Установка дефолтного значения флага отсутствия детей
    c.hasNotChildren = true
  })

  // * Обход категорий для создания дерева
  categories.forEach((c: Category) => {
    // * Пропуск категории "Все товары"
    if (c.id === -42) {
      return
    }
    // * Проверка наличия родительской категори
    if (c.parentId != null) {
      // * Если присутствует
      // * Проверка наличия категории в карте
      if (map[c.parentId] !== undefined) {
        // * Добавления категории в список подкатегорий родительской
        map[c.parentId]?.children ? map[c.parentId].children?.push(c) : map[c.parentId].children = [c]
        // * Изменение флага наличия подкатегорий
        map[c.parentId].hasNotChildren = !map[c.parentId]?.children?.length
      } else {
        // * Иначе
        // * Запись категории в дерево
        tree.push(c)
        // * Изменение флага наличия подкатегорий
        c.hasNotChildren = !c?.children?.length
      }
    } else {
      // * Иначе
      // * Запись в дерево
      tree.push(c)
      // * Изменение флага наличия подкатегорий
      c.hasNotChildren = !c?.children?.length
    }
  })

  return tree
};
