import Vue from 'vue'
import UiTable from '~/components/ui/ui-table/index.vue'
import UiDatePicker from '~/components/ui/date-picker'

import UiSelect from '~/components/ui/ui-select/index.vue'

const components = { UiTable, UiDatePicker, UiSelect }
Object
  .entries(components)
  .forEach(([name, component]) => {
    Vue.component(name, component)
  })
