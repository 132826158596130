import { Component, Vue } from 'nuxt-property-decorator'
import { StaticPagesParams } from './interfaces'

enum UpdateMsgs {
  sites = 'Сайты',
  roles = 'Роли'
}

@Component
export default class StaticPagesMixin extends Vue {
  /**
   * * Data
   */
  treeNodeMap = new Map()
  pagesSort = {} as Record<number, number>

  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$pages.resetCurrentPage()
    next()
  }

  /**
   * * Получение списка страниц
   * @param pageParams - параметры пагинации
   * @param save - флаг сохранения значений в store
   * @returns список страниц
   */
  async getPages (pageParams?: StaticPagesParams, save?: boolean) {
    try {
      this.$wait.start('getPages')
      return await this.$pages.getPages(pageParams, save)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getPages')
    }
  }

  /**
   * * Получение страницы по id
   * @param id - id страницы
   * @param save -флаг сохранения значения в store
   * @returns статическая страница
   */
  async getPage (id: number, save?: boolean) {
    try {
      this.$wait.start('getPage')
      return await this.$pages.getPage(id, save)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getPage')
    }
  }

  /**
   * * Создание страницы
   * @returns статическая страница
   */
  async createPage () {
    try {
      this.$wait.start('createPage')
      const data = await this.$pages.createPage()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Страница ${data.title} создана`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createPage')
    }
  }

  /**
   * * Изменение страницы
   * @returns статическая страница
   */
  async editPage () {
    try {
      this.$wait.start('editPage')
      const data = await this.$pages.editPage()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Страница ${data.title} изменена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editPage')
    }
  }

  /**
   * * Удаление страницы
   * @param id - id страницы
   * @returns статическая страница
   */
  async removePage (id: number) {
    try {
      this.$wait.start('removePage')
      const data = await this.$pages.removePage(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Страница ${data.title} удалена`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removePage')
    }
  }

  /**
   * * Обновляет дерево статических страниц при изменение parentId
   * @param data - статическая страница листья которой мы хотим обновить
   */
  async refreshLeafsPages (data: any) {
    try {
      const node = this.treeNodeMap.get(data.parentId)
      this.$wait.start('getPages')
      if (node === undefined) {
        await this.getPages({ parentId: null }, true)
        this.$pages.pages.data.map((page) => {
          this.pagesSort = {
            ...this.pagesSort,
            [String(page.id)]: page.sort
          }
        })
      } else {
        const { treeNode, resolve } = node
        return await this.loadPages({ id: data.parentId }, treeNode, resolve)
      }
    } catch (e: any) {} finally { this.$wait.end('getPages') }
  }

  /**
   * * Лоудер для статических страниц в таблице
   * @param page - id категории, листья которой мы открываем
   * @param treeNodeMap - дерево статических страниц
   * @param resolve - функция записи значения
   */
  async loadPages (page: { id: number }, treeNode: any, resolve: (arg0: any) => void) {
    try {
      const { data } = await this.$pages.getPages({ parentId: page.id }, false)
      data.map((page: { sort?: number | null, id?: number }) => {
        this.pagesSort = {
          ...this.pagesSort,
          [String(page.id)]: page.sort
        }
      })
      this.treeNodeMap.set(page.id, { treeNode, resolve })
      resolve(data)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    }
  }

  /**
   * * Обработчик запроса на привязку роли к дочерним страницам
   * @param api алиас применяемого свойства
   * @param id id родительской страницы
   */
  async updateChildren (api: 'sites' | 'roles', id: number) {
    try {
      this.$wait.start(`updateChildren_${api}`)
      // * Предварительный запрос на изменение страницы
      await this.$pages.editPage()
      // * Запрос на привязку ролей к дочерним страницам
      await this.$pages.updateChildren({ api, id })
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `${UpdateMsgs[api]} дочерних страниц обновлены`
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end(`updateChildren_${api}`)
    }
  }
}
