import { BonusLogPlugin, Filters, BonusLog, ValidatorsParams } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'

export default function getPluginApi (bonusLogStore:any) {
  const api: BonusLogPlugin = {
    // ? ______________ getters ______________

    get bonusLogs (): ResponseData<BonusLog> {
      return bonusLogStore.bonusLogs
    },

    get bonusLog (): BonusLog {
      return bonusLogStore.bonusLog
    },

    get filters (): Filters {
      return bonusLogStore.filters
    },
    get validators (): ValidatorsParams {
      return bonusLogStore.validators
    },
    // ? ______________ getters ______________

    set bonusLogs (bonusLogs: ResponseData<BonusLog>) {
      bonusLogStore.setBonusLogs(bonusLogs)
    },

    set bonusLog (bonusLog: BonusLog) {
      bonusLogStore.setBonusLog(bonusLog)
    },

    set filters (filters: Filters) {
      bonusLogStore.setFilters(filters)
    },

    resetBonusLogs (): void {
      bonusLogStore.resetBonusLogs()
    },

    resetBonusLog (): void {
      bonusLogStore.resetBonusLog()
    },

    resetFilters (): void {
      bonusLogStore.resetFilters()
    },

    // ? ______________ actions ______________

    getBonusLogs (pageParams: PageParams | null = null): Promise<ResponseData<BonusLog>> {
      return bonusLogStore.getBonusLogs(pageParams)
    },

    createBonusLog (): Promise<BonusLog> {
      return bonusLogStore.createBonusLog()
    },

    getBonusLogById (id: number): Promise<BonusLog> {
      return bonusLogStore.getBonusLogById(id)
    }
  }

  return api
}
