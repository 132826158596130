import { Component, Vue } from 'nuxt-property-decorator'
import { ExcelType } from './interfaces'
import { PageParams } from '~/store/interfaces'

@Component
export default class OrdersMixin extends Vue {
  /**
   * Computed properties
   */
  get formattedOrderPriceHeader () {
    return `Итого: \n \u00AD ${this.$format.price({ price: this.$orders.ordersList.prices.full_price }, { round: 'up' })}`
  }

  get formattedOrderTotalPriceHeader () {
    return `Итого к оплате: \n \u00AD ${this.$format.price(this.$orders.ordersList.prices, { round: 'up' })}`
  }

  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    // this.$orders.resetCurrentOrder()
    this.$orders.resetFilters()
    next()
  }

  /**
   **  Запрос на цену заказа
   * @returns цена заказа
   */
  async getOrderPrice () {
    try {
      this.$wait.start('getOrderPrice')
      return await this.$orders.getOrderPrice()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrderPrice')
    }
  }

  /**
   ** Запрос на список заказов
   * @param pageParams - параметры пагинатора
   * @returns список заказов
   */
  async getOrders (pageParams: PageParams) {
    try {
      this.$wait.start('getOrders')
      return await this.$orders.getOrders(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrders')
    }
  }

  /**
   ** Запрос на заказ
   * @param id - id заказа
   * @returns заказ
   */
  async getOrder (id: number) {
    try {
      this.$wait.start('getOrder')
      return await this.$orders.getOrder(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrder')
    }
  }

  /**
   ** Запрос на создание заказа
   * @param id - id пользователя
   * @returns заказ
   */
  async createOrder (id: number) {
    try {
      this.$wait.start('createOrder')
      const data = await this.$orders.createOrder(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлен заказ № ${data.orderId}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOrder')
    }
  }

  /**
   ** Запрос на применение скидки к товарам в заказе
   * @param {id, discount} - id заказа, discount значение скидки
   */
  async applyDiscount (id: number, discount: number) {
    try {
      this.$wait.start('applyDiscount')
      const data = await this.$orders.applyDiscount(id, discount)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Скидка применена к товарам в заказе № ${id}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('applyDiscount')
    }
  }

  /**
   ** Запрос на изменение заказа
   * @returns заказ
   */
  async editOrder () {
    try {
      this.$wait.start('editOrder')
      const data = await this.$orders.editOrder()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Заказ № ${data.mode === 'preorder' ? 'П-' : ''}${data.id} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editOrder')
    }
  }

  /**
   ** Запрос на удаление заказа
    @param id id заказа
   * @returns заказ
   */
  async removeOrder (id:number) {
    try {
      this.$wait.start('removeOrder')
      const data = await this.$orders.removeOrder(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Заказ ${data?.id || ''} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeOrder')
    }
  }

  // * Обновление занчений фильтров dateFrom, dateTo
  updateOrderFiltersDates (e: any) {
    if (!e) {
      this.$orders.filters = {
        ...this.$orders.filters,
        dateFrom: undefined,
        dateTo: undefined
      }
    } else {
      this.$orders.filters = {
        ...this.$orders.filters,
        dateFrom: e.length ? e[0] / 1000 : undefined,
        dateTo: e.length ? e[1] / 1000 : undefined
      }
    }
  }

  formattedOrderStatus (row: any) {
    return this.$orderStatuses.orderStatusById(row.statusId)?.name || ''
  }

  formattedOrderStatusGroup (row: any) {
    return this.$orderStatusGroups.orderStatusGroupById(row.statusGroupsId) || { name: '' }
  }

  formattedOrderPrice (row: any) {
    return this.$format.price({ price: row.prices.oldPrice })
  }

  updateOrderFiltersValue (key: string, value: any) {
    this.$orders.filters = { ...this.$orders.filters, [key]: value !== '' ? value : undefined }
  }

  /**
   * * Обработчик запроса выгрузки данных в excel файл
   * @param reqType роут, по которому необходимо выполнить запрос
   * @param pageParams параметры фильтрации
   * @returns excel файл
   */
  async exportExcelOrders (reqType: ExcelType = ExcelType.EXCEL, pageParams?: PageParams) {
    try {
      this.$wait.start('exportExcel')
      return await this.$orders.exportExcel(reqType, pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('exportExcel')
    }
  }
}
