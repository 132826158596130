import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ValidatorParams } from '../../interfaces'
import { Partner, Filters } from './interfaces'
import { PageParams, ResponseData } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'partners',
  stateFactory: true,
  namespaced: true
})

export default class PartnersModule extends VuexModule {
  /**
   * * Массив всех partner исходя из запроса
   */
  partners: ResponseData<Partner> = defaultData

  /**
   * * Текущая partner
   */
  partner: Partner = {
    title: undefined,
    code: '',
    active: true,
    dropdown: true,
    sort: 0,
    previewDescr: '',
    descr: '',
    optionValueId: null,
    partnerImages: [],
    sizeTable: '{}'
  }

  /**
   * * Фильтры partner
   */

  filtersValue: Filters = {
    title: undefined
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название бренда',
        trigger: ['blur']
      }],
      code: [{
        required: true,
        pattern: validatorsPattern.emptyStringEmpty,
        message: 'Введите код бренда',
        trigger: ['blur']
      }
      ]
    }
  }

  /**
   * * Получить фильтры partners
   */
  get filters (): Filters {
    return this.filtersValue
  }

  /**
   * * Получить массив partners и пагинацией
   */
  get partnersList (): ResponseData<Partner> {
    return this.partners
  }

  /**
   * * Валидна ли форма
   */
  get validateData (): boolean {
    return !!(this.partner.title && this.partner.code)
  }

  /**
   * * Получить partner из массива partners
   */
  get partnerById () {
    const partners = this.partners
    return function (id: number): Partner | undefined {
      return partners.data.find(partner => partner.id === id)
    }
  }

  /**
   * * Получить текущую partner для измения или создания partner
   */
  get currentPartner () {
    return this.partner
  }

  // ? ______________ setters ______________

  /**
   * * Установить фильтры Partners
   * @param filter значение фильтров
   */
  @Mutation
  setFilters (filter: Filters) {
    this.filtersValue = filter
  }

  /**
   * * Очистить фильтры Partners
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      title: undefined
    }
  }

  /**
   * * Установить массив Partners
   * @param partners массив Partners
   */
  @Mutation
  setPartnersList (partners: ResponseData<Partner>) {
    this.partners = partners
  }

  /**
   * * Установить CurrentPartner для измения или создания partner
   * @param partner текущая Partner, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentPartner (partner: Partner) {
    this.partner = partner
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentPartner () {
    this.partner = {
      title: undefined,
      code: '',
      active: true,
      dropdown: true,
      sort: 0,
      previewDescr: '',
      descr: '',
      optionValueId: null,
      partnerImages: [],
      sizeTable: '{}'
    }
  }

  @Mutation
  resetPartners () {
    this.partners = defaultData
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Partners по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setPartnersList'
  })
  async getPartners (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/partners', { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<Partner> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить partner по id
   * @param id id partner, который мы хотим получить
   */
  @Action({
    rawError: true, commit: 'setCurrentPartner'
  })
  async getPartner (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/partners/${id}`)
      const response: Partner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить partner
   */
  @Action({
    rawError: true
  })
  async editPartner () {
    try {
      const { id, ...partner } = this.currentPartner
      const { data: { data } } = await $axios.put(`/shop/partners/${id}`, partner)
      const response: Partner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Partner
   */
  @Action({
    rawError: true
  })
  async createPartner () {
    try {
      const { data: { data } } = await $axios.post('/shop/partners', this.currentPartner)
      const response: Partner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить partner по id
   * @param id  id partner, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removePartner (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/partners/${id}`)
      const response: Partner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
