import { Component, Vue } from 'nuxt-property-decorator'
import { ProductInput, Params } from './interfaces'

@Component
export default class ProductsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$products.resetFilters()
    this.$products.resetCurrentProduct()
    next()
  }

  /**
   * * Запрос на получение списка товаров
   * @param productInput - параметры запроса
   * @returns список товаров
   */
  async getProducts (productInput: ProductInput) {
    try {
      this.$wait.start('getProducts')
      return await this.$products.getProducts(productInput)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProducts')
    }
  }

  /**
   * * Запрос на обновление сортировки товаров
   */
  async updateProductsSort () {
    this.$wait.start('updateProductsSort')
    try {
      await this.$products.updateProductsSort()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Сортировка товаров обновлена'
      })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('updateProductsSort') }
  }

  /**
   * * Запрос на получение товара по id
   * @param id - id товара
   * @returns товар
   */
  async getProductById (id: number) {
    try {
      this.$wait.start('getProductById')
      return await this.$products.getProduct(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductById')
    }
  }

  /**
   * * Получение списка товаров по id`s
   * @param params - параметры пагинации и id`s товаров
   * @returns список товаров
   */
  async getProductsById (params: Params) {
    try {
      this.$wait.start('getProductsById')
      return await this.$products.getProductsById(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getProductsById')
    }
  }

  /**
   * * Запрос на создание товара
   * @returns товар
   */
  async createProduct () {
    try {
      this.$wait.start('createProduct')
      const data = await this.$products.createProduct()
      this.$notify({
        type: 'success',
        title: 'Успешно',
        message: `Товар ${data.title} добавлен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createProduct')
    }
  }

  /**
   * * Запрос на изменение товара
   * @returns товар
   */
  async editProduct () {
    try {
      this.$wait.start('editProduct')
      const data = await this.$products.editProduct()
      this.$notify({
        type: 'success',
        title: 'Успешно',
        message: `Товар ${data.title} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editProduct')
    }
  }

  /**
   * * Обновление товаров
   */
  async updateProducts () {
    try {
      this.$wait.start('updateProducts')
      await this.$products.updateProducts()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Товары обновлены'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateProducts')
    }
  }

  /**
   * * Получение excel файла с отчётом по товарам с нулевой ценой
   */
  async exportZeroPriceExcel () {
    try {
      this.$wait.start('exportZeroPriceExcel')
      return await this.$products.exportZeroPriceExcel()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('exportZeroPriceExcel')
    }
  }

  /**
   * * Получение excel файла с отчётом по товарам с нулевым весом
   */
  async exportZeroWeightExcel () {
    try {
      this.$wait.start('exportZeroWeightExcel')
      return await this.$products.exportZeroWeightExcel()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('exportZeroWeightExcel')
    }
  }
}
