import { PickUserOptions, User } from '~/store/modules/users/interfaces'

export const userParams: Record<keyof Pick<User, PickUserOptions>, string> = {
  showPreorderProducts: 'Видит товары предзаказ',
  showHalfStuffProducts: 'Видит товары полуфабрикаты',
  showPreparedProducts: 'Видит товары в разработке',
  createPreparedProducts: 'Может создавать товары в разработке',
  loadPreparedProducts: 'Может скачивать файлы товаров в разработке',
  showProductRemnants: 'Видит остатки по складам'
}
