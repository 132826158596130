
/**
 * * Объект параметров заказов
 */

export interface OrdersParams {
  orderCountByCity?: Record<string, number>
  newOrdersCount: number
  totalOrdersSum: string
  sumPerNewOrder: string
  newUsersCount: number
}

/**
 * * Объект параметров forms
 */

export interface FormsParams {
  forms: FormParams[]
}
/**
 * * Объект параметров form
 */
export interface FormParams {
  name: string
  forms_count: number
}

/**
 * * Объект параметров Statuses
 */

export interface StatusesParams {
  orders: StatusParams[]
}
/**
 * * Объект параметров Status
 */
export interface StatusParams {
  order_month?: string
  order_day?: string
  name?: string
  count: number
  sum: string
}

/**
/**
 * * Объект inputParams
 */
export interface InputParams {
  dateFrom?: number
  dateTo?: number
  statuses?: number[]
}

export interface StatusesByDayOrderItem {
  name: string,
  count: number,
  sum: number
}

export interface StatusesByDayOrder {
  date: string,
  items: StatusesByDayOrderItem[]
}

export interface StatusesByDayResp {
  orders: StatusesByDayOrder[]
}

/**
 * * Объект плагина
 */
export interface MainPagePlugin {
  /**
   * * Orders
   */
  orders: OrdersParams
  /**
   * * запрос на получение Orders города
   */
  getOrdersGeography: (inputParams?: InputParams | null) => Promise<{orderCountByCity: Record<string, number>}>
  /**
   * * запрос на получение Orders статистика покупок
   */
  getOrderUser: (inputParams?: InputParams | null) => Promise<OrdersParams>
  /**
   * * Очистить стор главной страницы
   */
  resetMainPage: () => void
  /**
   * * Forms
   */
  forms: FormsParams
  /**
   * * запрос на получение Forms
   */
  getForms: (inputParams?: InputParams | null) => Promise<FormsParams>
  /**
   * * Statuses
   */
  statuses: StatusesParams
  /**
   * * запрос на получение Statuses
   */
  getStatuses: (inputParams?: InputParams | null) => Promise<StatusesParams>
  /**
   * * days
   */
  days: StatusesParams
  /**
  * * запрос на получение days
  */
  getDays: (inputParams?: InputParams | null) => Promise<StatusesParams>
  /**
   * * months
   */
  months: StatusesParams
  /**
   * * запрос на получение month
   */
  getMonths: (inputParams?: InputParams | null) => Promise<StatusesParams>
  /**
   * * Список ежедневной статистики по заказам с учетом статуса
   */
  statusesByDay: StatusesByDayResp
  /**
   * * Запрос на список ежедневной статистики по заказам с учетом статуса
   */
  getStatusesByDay: (inputParams?: InputParams | null) => Promise<StatusesByDayResp>
  /**
   * * Запрос на excel файл со списоком ежедневной статистики по заказам с учетом статуса
   */
  getStatusesByDayExcel: (inputParams?: InputParams | null) => Promise<Blob>
}
