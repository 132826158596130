import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { Site } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'sites',
  stateFactory: true,
  namespaced: true
})
export default class SitesModule extends VuexModule {
  /**
   * * Сайты
   */
  sitesValue: ResponseData<Site> = defaultData

  /**
   * * Сайт
   */
  siteValue: Site = {
    name: '',
    code: '',
    inMaintenance: false,
    maintenanceMessage: '',
    params: {
      seo: {
        title: '',
        description: ''
      }
    }
  }

  // ? ______________ getters ______________

  /**
   * * Получить список сайтов
   */
  get sites (): ResponseData<Site> {
    return this.sitesValue
  }

  /**
   * * Получить сайт
   */
  get site (): Site {
    return this.siteValue
  }

  /**
   * * Получить сайт по id
   */
  get siteById () {
    const sites = this.sitesValue
    return function (id: number): Site | undefined {
      return sites.data.find(site => site.id === id)
    }
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название сайта (Город)', trigger: ['blur', 'change'] }],
      code: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите код сайта', trigger: ['blur'] }],
      maintenanceMessage: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите сообщение', trigger: ['blur'] }]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить список сайтов
   */
  @Mutation
  setSites (sites: ResponseData<Site>) {
    this.sitesValue = sites
  }

  /**
   * * Установить сайт
   */
  @Mutation
  setSite (site: Site) {
    this.siteValue = site
  }

  /**
   * * Сбросить сайт
   */
  @Mutation
  resetSite () {
    this.siteValue = {
      name: '',
      code: '',
      inMaintenance: false,
      maintenanceMessage: '',
      params: {
        seo: {
          title: '',
          description: ''
        }
      }
    }
  }

  /**
   * * Сбросить сайт
   */
  @Mutation
  resetSites () {
    this.sitesValue = defaultData
  }

  // ? ______________ actions ______________

  /**
   * * Получить список сайтов
   * @param pageParams - параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setSites'
  })
  async getSites (params: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/sites', { params })
      const response: ResponseData<Site> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить сайт по id
   */
  @Action({
    rawError: true,
    commit: 'setSite'
  })
  async getSiteById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/sites/${id}`)
      const response: Site = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать сайт
   */
  @Action({
    rawError: true
  })
  async createSite () {
    try {
      const { data: { data } } = await $axios.post('/sites', this.site)
      const response: Site = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить сайт
   */
  @Action({
    rawError: true
  })
  async editSite () {
    try {
      const { id, ...newSite } = this.site
      const { data: { data } } = await $axios.put(`/sites/${id}`, newSite)
      const response: Site = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить сайт
   */
  @Action({
    rawError: true
  })
  async removeSite (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/sites/${id}`)
      const response: Site = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
