import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class FormsParamsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$formParams.resetFormParam()
    this.$formParams.resetFormParamType()
    next()
  }

  // ? ___________Form Params___________
  /**
   * * Получение списка параметров формы
   * @param typeId - id формы
   * @returns список параметров формы
   */
  async getFormParams (typeId?: number) {
    try {
      this.$wait.start('getFormParams')
      return await this.$formParams.getFormParams({ typeId })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getFormParams') }
  }

  /**
   * * Добавление параметра
   * @returns параметр
   */
  async createFormParam () {
    try {
      this.$wait.start('createParam')
      const data = await this.$formParams.createFormParam()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Добавлен новый параметр'
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createParam')
    }
  }

  /**
   * * Удаление параметра
   * @param ids - id парметров [1]
   * @returns параметр
   */
  async removeFormParam (ids: number[]) {
    try {
      this.$wait.start('removeParam')
      const data = await this.$formParams.removeFormParam(ids[0])
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Параметр удален'
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeParam')
    }
  }

  // ? ___________Form Param Types___________
  /**
   * * Сделать запрос на получение типов параметров
   * @param type - тип модуля (review, form)
   * @returns список типов параметров
   */
  async getFormParamTypes (type: 'form' | 'review' | undefined = undefined) {
    try {
      this.$wait.start('getFormParamTypes')
      return await this.$formParams.getFormParamTypes({ type })
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
    } finally {
      this.$wait.end('getFormParamTypes')
    }
  }

  /**
   * * Сделать запрос изменение типа параметра
   * @returns тип параметра
   */
  async editFormParamType () {
    try {
      this.$wait.start('changeFormType')
      const data = await this.$formParams.editFormParamType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменен тип параметров "${data.name}"`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeFormType') }
  }

  /**
   * * Сделать запрос на создание типа параметра
   * @returns тип параметра
   */
  async createFormParamType () {
    try {
      this.$wait.start('changeFormType')
      const data = await this.$formParams.createFormParamType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Создан тип параметров "${data.name}"`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeFormType') }
  }

  /**
   * * Сделать запрос на удаление типа параметра
   * @param id - id типа параметрв
   * @returns тип параметра
   */
  async removeFormParamType (id : number) {
    try {
      this.$wait.start('removeParamType')
      const data = await this.$formParams.removeFormParamType(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип параметра ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeParamType') }
  }
}
