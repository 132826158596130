import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class BonusLogMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$bonusLog.resetBonusLog()
    next()
  }

  /**
   * * Получение логов бонусов
   * @param pageParams - параметры пагинации (необязательные)
   * @returns список бонус логов
   */
  async getBonusLogs (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getBonusLogs')
      return await this.$bonusLog.getBonusLogs(pageParams)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusLogs')
    }
  }

  /**
   * * Создание лога бонуса
   * @returns лог бонуса
   */
  async createBonusLog () {
    try {
      this.$wait.start('createBonusLog')
      const data = await this.$bonusLog.createBonusLog()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Бонусы ${data.operationType === 1 ? 'начислены' : 'списаны'}`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createBonusLog')
    }
  }

  /**
   * * Получение лога бонуса
   * @param id - id лога бонуса
   * @returns лог бонуса
   */
  async getBonusLogById (id: number) {
    try {
      this.$wait.start('getBonusLogById')
      return await this.$bonusLog.getBonusLogById(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBonusLogById')
    }
  }
}
