import { OrderOffersPlugin, OrderOffer, OrderOfferData, Ids, Params, Filters } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (orderOffersStore:any) {
  const api: OrderOffersPlugin = {
    // ? ------ getters ------ //

    get orderOffers (): ResponseData<OrderOffer> {
      return orderOffersStore.orderOffers
    },

    get orderOfferData (): OrderOfferData {
      return orderOffersStore.orderOfferData
    },

    get validators (): ValidatorParams {
      return orderOffersStore.validators
    },

    get filters (): Filters {
      return orderOffersStore.filters
    },

    // ? ------ setters ------ //

    set filters (filters: Filters) {
      orderOffersStore.setFilters(filters)
    },

    resetFilters () {
      orderOffersStore.resetFilters()
    },

    set orderOffers (orderOffers: ResponseData<OrderOffer>) {
      orderOffersStore.setOrderOffers(orderOffers)
    },

    set orderOfferData (orderOfferData: OrderOfferData) {
      orderOffersStore.setOrderOfferData(orderOfferData)
    },

    resetOrderOfferData () {
      orderOffersStore.resetOrderOfferData()
    },

    resetOrderOffers () {
      orderOffersStore.resetOrderOffers()
    },

    // ? ------ actions ------ //

    getOrderOffers (params: Params): Promise<ResponseData<OrderOffer>> {
      return orderOffersStore.getOrderOffers(params)
    },

    createOrderOffer (): Promise<OrderOffer> {
      return orderOffersStore.createOrderOffer()
    },

    editOrderOffer (): Promise<OrderOffer> {
      return orderOffersStore.editOrderOffer()
    },

    removeOrderOffer (ids: Ids): Promise<OrderOffer> {
      return orderOffersStore.removeOrderOffer(ids)
    },

    exportExcel (orderId: number): Promise<Blob> {
      return orderOffersStore.exportExcel(orderId)
    }
  }

  return api
}
