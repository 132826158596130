
























































import Vue from 'vue'
import Paginator from '@/components/ui/paginator/index.vue'
import { Component, Prop } from 'nuxt-property-decorator'

@Component({
  components: {
    Paginator
  }
})
export default class UiTable extends Vue {
  /**
   * *________________ Props ______________________
   */
  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
  vLoading!: boolean

  @Prop({
    type: Array,
    required: false,
    default: () => ([])
  })
  tableData!: any[]

  @Prop({
    type: Object,
    required: false,
    default: () => null
  })
  paginationData!: any

  @Prop({
    type: Object,
    required: false,
    default: () => ({})
  })
  tableProps!: any

  /**
   * *___________ Computed ________________________
   */
  get hidePaginator () {
    return this.tableProps.hideOneItemPaginator ? this.paginationData.totalItems <= 2 : false
  }
}
