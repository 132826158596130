import { AxiosError } from 'axios'
import { Context } from '@nuxt/types'
import { DadataPlugin } from './interface'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

export default function getPluginApi (): DadataPlugin {
  const ctx: Context = this

  const api: DadataPlugin = {
    async fetch (address: string) {
      try {
        const { data } = await $axios.get(
          `${ctx.$config.SITE_API}/dadata/suggest/address?query=${address}`
        )
        return data
      } catch (error) {
        console.error(error)
        throw new FormError(error as AxiosError<FormError>)
      }
    }
  }

  return api
}
