




import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

@Component({
  middleware: ['auth']
})
export default class SignInLayout extends Vue {
  public head () {
    return {
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.$config.S3_DOMAIN + '/icons/favicon.ico' }]
    }
  }
}

