// * Значения параметра фиксации столбца
export enum FIXED {
  RIGHT = 'right',
  LEFT = 'left',
}

// * Конфигурация столбца
export interface TABLE_CONFIG_ITEM {
  label: string;
  visible: boolean;
  fixed: FIXED | false;
}

// * Конфигурация таблицы
export interface TABLE_CONFIG {
  [key: string]: TABLE_CONFIG_ITEM;
}

// * Конфигурация таблицы заказов
export interface ORDERS_TABLE_CONFIG extends TABLE_CONFIG {
  ID: TABLE_CONFIG_ITEM;
  CREATED_AT: TABLE_CONFIG_ITEM;
  EMAIL: TABLE_CONFIG_ITEM;
  USER_ORDERS_COUNT: TABLE_CONFIG_ITEM;
  ROLE: TABLE_CONFIG_ITEM;
  CONTACT_PERSON: TABLE_CONFIG_ITEM;
  PHONE: TABLE_CONFIG_ITEM;
  STATUS_ID: TABLE_CONFIG_ITEM;
  STATUS_GROUPS_ID: TABLE_CONFIG_ITEM;
  IS_PAYED: TABLE_CONFIG_ITEM;
  PRICE: TABLE_CONFIG_ITEM;
  TOTAL_PRICE: TABLE_CONFIG_ITEM;
  ACTIONS: TABLE_CONFIG_ITEM;
  MODE: TABLE_CONFIG_ITEM;
}
