import { PointsPlugin, Point, DillerData } from './interfaces'
import { PageParams, ResponseData, ValidatorParams, Filters } from '~/store/interfaces'

export default function getPluginApi (pointsStore:any) {
  const api: PointsPlugin = {
    // ? ________ getters ________

    get filters (): Filters & DillerData {
      return pointsStore.filters
    },

    get pointsList (): ResponseData<Point> {
      return pointsStore.pointsList
    },

    get validators (): ValidatorParams {
      return pointsStore.validators
    },

    pointById (id: number): Point | undefined {
      return pointsStore.pointById(id)
    },

    get currentPoint (): Point {
      return pointsStore.currentPoint
    },

    // ? ________ setters ________

    set filters (filter: Filters & DillerData) {
      pointsStore.setFilters(filter)
    },

    set pointsList (points: ResponseData<Point>) {
      pointsStore.setPointsList(points)
    },

    resetCurrentPoint () {
      pointsStore.resetCurrentPoint()
    },

    resetFilters () {
      pointsStore.resetFilters()
    },

    resetPointsList () {
      pointsStore.resetPointsList()
    },

    set currentPoint (point: Point) {
      pointsStore.setCurrentPoint(point)
    },

    // ? ________ actions ________

    getPoints (pageParams: PageParams | null = null): Promise<ResponseData<Point>> {
      return pointsStore.getPoints(pageParams)
    },

    getPoint (id: number): Promise<Point> {
      return pointsStore.getPoint(id)
    },

    removePoint (id: number): Promise<Point> {
      return pointsStore.removePoint(id)
    },

    editPoint (): Promise<Point> {
      return pointsStore.editPoint()
    },

    createPoint (): Promise<Point> {
      return pointsStore.createPoint()
    }
  }
  return api
}
