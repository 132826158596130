import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'
import { MessageType } from 'element-ui/types/message'
import { ValidationObject } from './interfaces'

@Component
export default class FormValidationMixin extends Vue {
  // ____________ Methods ____________
  validateForms (vObjs: ValidationObject[], isAlert: boolean = true) {
    try {
      if (!vObjs.length) { return { isValid: true, idx: -1 } }
      let isValid: boolean
      const vObjIdx = vObjs?.findIndex((vObj: ValidationObject) => {
        if (!vObj?.ref) {
          isValid = true
          return !isValid
        }
        vObj?.ref?.validate((valid: any) => {
          isValid = !!valid
        })
        return !isValid
      })
      if (vObjIdx === -1) { return { isValid: true, idx: -1 } }
      if (isAlert) {
        this.$notify({
          type: vObjs?.[vObjIdx]?.alert?.type as MessageType || 'warning',
          title: vObjs?.[vObjIdx]?.alert?.title || 'Ошибка валидации',
          message: vObjs?.[vObjIdx]?.alert?.message || 'Заполните выделенные поля'
        })
      }
      return { isValid: false, idx: vObjIdx }
    } catch (error) {
      console.error(error)
    }
  }

  clearFormValidation (refs: any[]) {
    if (!refs.length) { return }
    refs.forEach((ref: any) => {
      ref?.clearValidate()
    })
  }
}
