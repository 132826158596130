import { FormParamsPlugin, FormParam, FormParamType, Params } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (formParamsStore:any) {
  const api: FormParamsPlugin = {
    // ? ________ getters ________
    get formParams (): ResponseData<FormParam> {
      return formParamsStore.formParams
    },

    get formParam (): FormParam {
      return formParamsStore.formParam
    },
    get paramTypeParamsValidators (): ValidatorParams {
      return formParamsStore.paramTypeParamsValidators
    },

    formParamById (id: number): FormParam | undefined {
      return formParamsStore.formParamById(id)
    },

    get formParamTypes (): ResponseData<FormParamType> {
      return formParamsStore.formParamTypes
    },

    get formParamType (): FormParamType {
      return formParamsStore.formParamType
    },

    formParamTypeById (id: number): FormParamType | undefined {
      return formParamsStore.formParamTypeById(id)
    },

    // ? ________ setters ________

    set formParams (formParams: ResponseData<FormParam>) {
      formParamsStore.setFormParams(formParams)
    },

    set formParam (formParam: FormParam) {
      formParamsStore.setFormParam(formParam)
    },

    resetFormParam () {
      formParamsStore.resetFormParam()
    },

    resetFormParams () {
      formParamsStore.resetFormParams()
    },

    set formParamTypes (formParamTypes: ResponseData<FormParamType>) {
      formParamsStore.setFormParamTypes(formParamTypes)
    },

    set formParamType (formParamType: FormParamType) {
      formParamsStore.setFormParamType(formParamType)
    },

    resetFormParamType () {
      formParamsStore.resetFormParamType()
    },

    resetFormParamTypes () {
      formParamsStore.resetFormParamTypes()
    },

    // ? ________ actions ________

    getFormParams (params: Params | null = null): Promise<ResponseData<FormParam>> {
      return formParamsStore.getFormParams(params)
    },

    getFormParamById (id: number): Promise<FormParam> {
      return formParamsStore.getFormParamById(id)
    },

    createFormParam (): Promise<FormParam> {
      return formParamsStore.createFormParam()
    },

    editFormParam (): Promise<FormParam> {
      return formParamsStore.editFormParam()
    },

    removeFormParam (id: number): Promise<FormParam> {
      return formParamsStore.removeFormParam(id)
    },

    getFormParamTypes (params: Params | null = null): Promise<ResponseData<FormParamType>> {
      return formParamsStore.getFormParamTypes(params)
    },

    getFormParamTypeById (id: number): Promise<FormParamType> {
      return formParamsStore.getFormParamTypeById(id)
    },

    createFormParamType (): Promise<FormParamType> {
      return formParamsStore.createFormParamType()
    },

    editFormParamType (): Promise<FormParamType> {
      return formParamsStore.editFormParamType()
    },

    removeFormParamType (id: number): Promise<FormParamType> {
      return formParamsStore.removeFormParamType(id)
    }
  }

  return api
}
