import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

export interface DeliveryPoint {
  pointCode: string,
  name: string,
  addressValue: string,
  coord: number[]
}

export interface RequestParams {
  addressValue: string,
  zip?: string,
  kladrId?: string,
  country?: string,
  city?: string,
  optionCodes: string[]
}

export default ({ $axios, $config }: Context, inject: Inject) => {
  inject('deliveryPoints', async (requestParams: RequestParams): Promise<DeliveryPoint[]> => {
    return (await $axios.post(
      `${$config.SITE_API}/delivery/points`,
      { ...requestParams }
    )).data
  })
}
