import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import {
  Raffle,
  RafflesRequestFilters,
  RequestParams,
  RafflesResponse,
  ParticipantsRequestFilters,
  ParticipantsResponse,
  ResponseMeta,
  Participant,
  UploadParticipantsModes,
  RaffleTypes
} from './interface'
import pageSizes from '~/utils/page-sizes'
import { FormError, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

const defaultMeta: ResponseMeta = {
  currentPage: 0,
  offset: 0,
  pageCount: 0,
  perPage: pageSizes.default,
  totalCount: 0
}

@Module({
  name: 'raffles',
  stateFactory: true,
  namespaced: true
})
export default class RafflesModule extends VuexModule {
  // ________________________ STATES ________________________ //

  // ------------ RAFFLES ------------ //

  // * Параметры фильтров списка розыгрышей
  private rafflesFiltersValue: RafflesRequestFilters = {
    code: undefined,
    description: undefined,
    name: undefined,
    type: undefined
  };

  // * Список розыгрышей
  private rafflesValue: RafflesResponse = {
    raffle: [],
    meta: defaultMeta
  };

  // * Розыгрыш
  private raffleValue: Raffle = {
    active: false,
    code: '',
    description: '',
    name: '',
    type: RaffleTypes.barcode,
    videoLink: '',
    videoId: null
  };

  // ------------ PARTICIPANTS ------------ //

  // * Параметры фильтров списка участников розыгрыша
  private participantsFiltersValue: ParticipantsRequestFilters = {
    displayName: undefined,
    email: undefined,
    fio: undefined,
    ticket: undefined
  };

  // * Список участников розыгрыша
  private participantsValue: ParticipantsResponse = {
    meta: defaultMeta,
    participants: []
  };

  // * Участник розыгрыша
  private participantValue: Participant = {
    active: true,
    displayName: '',
    fio: '',
    phone: '',
    // raffleCode: '',
    ticket: '',
    email: ''
  };

  // ________________________ GETTERS ________________________ //

  // ------------ RAFFLES ------------ //

  /**
   * * Получить Параметры фильтров списка розыгрышей
   */
  get rafflesFilters (): RafflesRequestFilters {
    return this.rafflesFiltersValue
  }

  /**
   * * Получить список розыгрышей
   */
  get raffles (): RafflesResponse {
    return this.rafflesValue
  }

  /**
   * * Получить розыгрыш
   */
  get raffle (): Raffle {
    return this.raffleValue
  }

  /**
   * * Получение розыгрыша из списка по id
   */
  get raffleById () {
    return function (id: number): Raffle | undefined {
      return (
        this.rafflesValue.raffle
          .slice()
          ?.find((raffle: Raffle) => raffle.id === id) ?? undefined
      )
    }
  }

  /**
   * * Получить валидаторы розыгрыша
   */
  get raffleValidators (): ValidatorParams {
    return {
      code: [{ required: true, message: 'Введите код', trigger: ['blur', 'change'] }],
      name: [{ required: true, message: 'Введите название', trigger: ['blur', 'change'] }],
      type: [{ required: true, message: 'Выберите тип', trigger: 'blur' }],
      description: [{ required: true, message: 'Введите описание', trigger: ['blur', 'change'] }]
    }
  }

  // ------------ PARTICIPANTS ------------ //

  /**
   * * Получить фильтры списка участников розыгрыша
   */
  get participantsFilters (): ParticipantsRequestFilters {
    return this.participantsFiltersValue
  }

  /**
   * * Получить список участников розыгрыша
   */
  get participants (): ParticipantsResponse {
    return this.participantsValue
  }

  /**
   * * Получить участника розыгрыша
   */
  get participant (): Participant {
    return this.participantValue
  }

  /**
   * * Получение участника розыгрыша из списка по id
   */
  get participantById () {
    return function (id: number): Participant | undefined {
      return (
        this.participantsValue.participants
          ?.slice()
          ?.find((participant: Participant) => participant.id === id) ??
        undefined
      )
    }
  }

  /**
   * * Получить валидаторы участника
   */
  get participantValidators (): ValidatorParams {
    return {
      fio: [{ required: true, message: 'Введите ФИО', trigger: ['blur', 'change'] }],
      displayName: [{ required: true, message: 'Введите отображаемое имя', trigger: ['blur', 'change'] }],
      email: [{ pattern: validatorsPattern.email, required: true, message: 'Введите корректный email', trigger: ['blur', 'change'] }],
      phone: [{ type: 'string', pattern: /^\+?[1-9]\d{1,14}$/, required: true, message: 'Введите номер телефона', trigger: ['change', 'blur'] }]
    }
  }

  // ________________________ SETTERS ________________________ //

  // ------------ RAFFLES ------------ //

  /**
   * * Установить параметры фильтров списка розыгрышей
   * @param filters - значения фильтров
   */
  @Mutation
  setRafflesFilters (filters: RafflesRequestFilters) {
    this.rafflesFiltersValue = filters
  }

  /**
   * * Сбросить фильтры списка розыгрышей
   */
  @Mutation
  resetRafflesFilters () {
    this.rafflesFiltersValue = {
      code: undefined,
      description: undefined,
      name: undefined,
      type: undefined
    }
  }

  /**
   * * Установить список розыгрышей
   * @param raffles - значение списка розыгрышей
   */
  @Mutation
  setRaffles (raffles: RafflesResponse) {
    this.rafflesValue = raffles
  }

  /**
   * * Сбросить список розыгрышей
   */
  @Mutation
  resetRaffles () {
    this.rafflesValue = {
      raffle: [],
      meta: {
        currentPage: 0,
        offset: 0,
        pageCount: 0,
        perPage: pageSizes.default,
        totalCount: 0
      }
    }
  }

  /**
   * * Установить розыгрыш
   * @param raffle - значение розыгрыша
   */
  @Mutation
  setRaffle (raffle: Raffle) {
    this.raffleValue = raffle
  }

  /**
   * * Сбросить розыгрыш
   */
  @Mutation
  resetRaffle () {
    this.raffleValue = {
      active: false,
      code: '',
      description: '',
      name: '',
      type: RaffleTypes.barcode,
      videoLink: '',
      videoId: null
    }
  }

  // ------------ PARTICIPANTS ------------ //

  /**
   * * Установить фильтры участников розыгрыша
   * @param filter - фильтры спика участников розыгрыша
   */
  @Mutation
  setParticipantsFilters (filters: ParticipantsRequestFilters) {
    this.participantsFiltersValue = filters
  }

  /**
   * * Сбросить фильтры участников розыгрыша
   */
  @Mutation
  resetParticipantsFilters () {
    this.participantsFiltersValue = {
      displayName: undefined,
      email: undefined,
      fio: undefined,
      ticket: undefined
    }
  }

  /**
   * * Установить список участников розыгрыша
   * @param participants - значения списока участников розыгрыша
   */
  @Mutation
  setParticipants (participants: ParticipantsResponse) {
    this.participantsValue = participants
  }

  /**
   * * Сбросить список участников розыгрыша
   */
  @Mutation
  resetParticipants () {
    this.participantsValue = {
      meta: defaultMeta,
      participants: []
    }
  }

  /**
   * * Установить участника розыгрыша
   * @param participant - значение участника розыгрыша
   */
  @Mutation
  setParticipant (participant: Participant) {
    this.participantValue = participant
  }

  /**
   * * Сбросить участника розыгрыша
   */
  @Mutation
  resetParticipant () {
    this.participantValue = {
      active: true,
      displayName: '',
      fio: '',
      phone: '',
      // raffleCode: '',
      ticket: '',
      email: ''
    }
  }

  // ________________________ ACTIONS ________________________ //

  // ------------ RAFFLES ------------ //

  /**
   * * Запрос на получение списка розыгрышей
   * @param param0 - { siteApiUrl, requestParams } - url апи сайта, параметры запроса
   * @returns - список розыгрышей
   */
  @Action({
    rawError: true,
    commit: 'setRaffles'
  })
  async getRaffles ({
    siteApiUrl,
    requestParams
  }: {
    siteApiUrl: string;
    requestParams?: RequestParams<keyof Raffle>;
  }): Promise<RafflesResponse> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/raffles/v1/admin`, {
        headers: {
          Authorization: '',
          common: {
            Authorization: ''
          }
        },
        params: {
          ...this.rafflesFiltersValue,
          ...requestParams
        }
      })
      return data as RafflesResponse
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание розыгрыша
   * @param param0 - { siteApiUrl } - url апи сайта
   * @returns - розыгрыш
   */
  @Action({
    rawError: true
  })
  async createRaffle ({ siteApiUrl }: { siteApiUrl: string }): Promise<Raffle> {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/raffles/v1/admin`,
        { ...this.raffle },
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        }
      )
      return data as Raffle
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение розыгрыша
   * @param param0 - { siteApiUrl } - url апи сайта
   * @returns - розыгрыш
   */
  @Action({
    rawError: true
  })
  async editRaffle ({ siteApiUrl }: { siteApiUrl: string }): Promise<Raffle> {
    try {
      const { data } = await $axios.patch(
        `${siteApiUrl}/raffles/v1/admin/${this.raffle.code}`,
        { ...this.raffle },
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        }
      )
      return data as Raffle
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление розыгрыша
   * @param param0 - { siteApiUrl, code } - url апи сайта, код розыгрыша
   * @returns - розыгрыш
   */
  @Action({
    rawError: true
  })
  async removeRaffle ({
    siteApiUrl,
    code
  }: {
    siteApiUrl: string;
    code: string;
  }): Promise<Raffle> {
    try {
      const { data } = await $axios.delete(`${siteApiUrl}/raffles/v1/admin/${code}`, {
        headers: {
          common: {
            Authorization: ''
          }
        }
      })
      return data as Raffle
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  // ------------ PARTICIPANTS ------------ //

  /**
   * * Запрос на получение списка участников розыгрыша
   * @param param0 - { siteApiUrl, requestParams } - url апи сайта, параметры запроса
   * @returns - список участников розыгрыша
   */
  @Action({
    rawError: true,
    commit: 'setParticipants'
  })
  async getParticipants ({
    siteApiUrl,
    requestParams
  }: {
    siteApiUrl: string;
    requestParams?: RequestParams<keyof Participant>;
  }): Promise<ParticipantsResponse> {
    try {
      const { data } = await $axios.get(
        `${siteApiUrl}/raffles/v1/admin/${this.raffleValue.code}`,
        {
          headers: {
            Authorization: '',
            common: {
              Authorization: ''
            }
          },
          params: {
            ...this.participantsFiltersValue,
            ...requestParams
          }
        }
      )
      return data as ParticipantsResponse
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание участника розыгрыша
   * @param param0 - { siteApiUrl } - url апи сайта
   * @returns - участник розыгрыша
   */
  @Action({
    rawError: true
  })
  async createParticipant ({
    siteApiUrl
  }: {
    siteApiUrl: string;
  }): Promise<Participant> {
    try {
      const { data } = await $axios.post(
        `${siteApiUrl}/raffles/v1/admin/participants/${this.raffleValue.code}`,
        { ...this.participant },
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        }
      )
      return data as Participant
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение данных участника розыгрыша
   * @param param0 - { siteApiUrl } - url апи сайта
   * @returns - участник розыгрыша
   */
  @Action({
    rawError: true
  })
  async editParticipant ({
    siteApiUrl
  }: {
    siteApiUrl: string;
  }): Promise<Participant> {
    const { data } = await $axios.patch(
      `${siteApiUrl}/raffles/v1/admin/participants/${this.participant.id}`,
      { ...this.participant },
      {
        headers: {
          common: {
            Authorization: ''
          }
        }
      }
    )
    return data as Participant
  }

  /**
   * * Запрос на удаление участника розыгрыша
   * @param param0 - { siteApiUrl, id } - url апи сайта, id участника розыгрыша
   * @returns - участник розыгрыша
   */
  @Action({
    rawError: true
  })
  async removeParticipant ({
    siteApiUrl,
    id
  }: {
    siteApiUrl: string;
    id: number;
  }): Promise<Participant> {
    try {
      const { data } = await $axios.delete(
        `${siteApiUrl}/raffles/v1/admin/participants/${id}`,
        {
          headers: {
            common: {
              Authorization: ''
            }
          }
        }
      )
      return data as Participant
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление всех участников розыгрыша
   * @param param0 - { siteApiUrl } - url апи сайта
   */
  @Action({
    rawError: true
  })
  async removeParticipants ({
    siteApiUrl,
    code
  }: {
    siteApiUrl: string;
    code: string;
  }): Promise<void> {
    try {
      await $axios.delete(`${siteApiUrl}/raffles/v1/admin/${code}/participants`, {
        headers: {
          common: {
            Authorization: ''
          }
        }
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение участников розыгрыша с помощью excel файла
   * @param param0 - { siteApiUrl, files, updateExists} - url апи сайта, файл для загрузки, режим обновления участников
   */
  @Action({
    rawError: true
  })
  async uploadParticipants ({
    siteApiUrl,
    file,
    updateExists
  }: {
    siteApiUrl: string;
    file: FormData;
    updateExists: UploadParticipantsModes;
  }): Promise<void> {
    try {
      await $axios.put(`${siteApiUrl}/raffles/v1/admin/${this.raffleValue.code}/participants`, file, {
        headers: {
          common: {
            Authorization: ''
          }
        },
        params: { updateExists }
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
