import { Component, Vue } from 'nuxt-property-decorator'
import { Ids, Params } from './interfaces'

@Component
export default class OrderOffersMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$orderOffers.resetOrderOfferData()
    this.$orderOffers.resetFilters()
    next()
  }

  /**
   * * Получение списка товаров в заказа
   * @param params - параметры пагинации и id заказа
   * @returns список товаров в заказе
   */
  async getOrderOffers (params: Params) {
    try {
      this.$wait.start('getOrderOffers')
      return await this.$orderOffers.getOrderOffers(params)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrderOffers')
    }
  }

  /**
   * * Добавление товара в заказ
   * @returns предложение товара
   */
  async createOrderOffer () {
    try {
      this.$wait.start('createOrderOffer')
      const data = await this.$orderOffers.createOrderOffer()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Товар №${data.id} добавлен в заказ`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createOrderOffer')
    }
  }

  /**
   * * Изменение товара в заказе
   * @returns предложение товара
   */
  async editOrderOffer () {
    try {
      this.$wait.start('editOrderOffer')
      const data = await this.$orderOffers.editOrderOffer()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Заказ №${this.$orders.currentOrder.mode === 'preorder' ? 'П-' : ''}${this.$orders.currentOrder.id} изменён`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editOrderOffer')
    }
  }

  /**
   * * Удаление товара из заказа
   * @param ids - id предложения товара и id заказа
   * @returns предложение товара
   */
  async removeOrderOffer (ids: Ids) {
    try {
      this.$wait.start('removeOrderOffer')
      const data = await this.$orderOffers.removeOrderOffer(ids)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Товар №${data.id} удалён из заказа`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('removeOrderOffer')
    }
  }

  /**
   * * Выгрузить в excel
   */
  async exportExcelOrderOffers (orderId: number) {
    try {
      this.$wait.start('exportExcel')
      return await this.$orderOffers.exportExcel(orderId)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('exportExcel')
    }
  }
}
