import { Context } from '@nuxt/types'
import { OrderTicketPlugin, OrderGetTicket, OrderRequest, Order } from './interfaces'
import OrderTicketModule from './store'

export default function getPluginApi (store: OrderTicketModule) {
  const ctx: Context = this
  const api: OrderTicketPlugin = {
    get ticket () {
      return store.ticket
    },

    get ticketScheme () {
      return store.ticketScheme
    },

    get errorsTicket () {
      return store.errorsTicket
    },
    // ? ________ setters ________

    set ticket (val: OrderGetTicket) {
      store.setTicketValue(val)
    },

    set errorsTicket (val: any) {
      store.setErrorsTicket(val)
    },

    set ticketScheme (val: OrderRequest) {
      store.setTicketSchemeValue(val)
    },
    // ? ________ actions ________

    getTicketValue (id: number | undefined): Promise<OrderGetTicket> {
      return store.getTicketValue({ siteApiUrl: ctx.$config.SITE_API, id })
    },
    createCdekTicket (id: number | undefined): Promise<any> {
      return store.createCdekTicket({ siteApiUrl: ctx.$config.SITE_API, id })
    },
    createDraftTicket (ticketValue: Order): Promise<any> {
      return store.createDraftTicket({ siteApiUrl: ctx.$config.SITE_API, ticketValue })
    },
    getTicketSchemeValue (id: number): Promise<OrderRequest> {
      return store.getTicketSchemeValue({ siteApiUrl: ctx.$config.SITE_API, id })
    },
    getStatusesByDayExcel (id: number): Promise<Blob> {
      return store.getStatusesByDayExcel({ siteApiUrl: ctx.$config.SITE_API, id })
    },
    getTicketBarcode (id: number | undefined): Promise<Blob> {
      return store.getTicketBarcode({ siteApiUrl: ctx.$config.SITE_API, id })
    },
    getTicketInvoice (id: number | undefined): Promise<Blob> {
      return store.getTicketInvoice({ siteApiUrl: ctx.$config.SITE_API, id })
    }
  }
  return api
}
