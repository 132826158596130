import { PagesPlugin, Page, StaticPagesParams } from './interfaces'
import PagesModule from './store'
import { ResponseData, ValidatorParams } from '~/store/interfaces'
import pagesTree from '~/utils/pages-tree'

export default function getPluginApi (pagesStore: PagesModule) {
  const api: PagesPlugin = {
    // ? ________ getters ________
    get pages (): ResponseData<Page> {
      return pagesStore.pages
    },
    get pagesTree (): any[] {
      const pages = JSON.parse(JSON.stringify(pagesStore.pages.data))
      return pagesTree(pages)
    },
    pageById (id: number): Page | undefined {
      return pagesStore.pageById(id)
    },
    get currentPage (): Page {
      return pagesStore.currentPage
    },
    get validators (): ValidatorParams {
      return pagesStore.validators
    },
    // ? ________ setters ________
    set pages (pages: ResponseData<Page>) {
      pagesStore.setPagesList(pages)
    },
    resetCurrentPage () {
      pagesStore.resetCurrentPage()
    },

    resetPages () {
      pagesStore.resetPages()
    },

    set currentPage (category: Page) {
      pagesStore.setCurrentPage(category)
    },
    // ? ________ actions ________
    async getPages (pageParams: StaticPagesParams | null = null, save: boolean = true): Promise<ResponseData<Page>> {
      if (save) {
        pagesStore.setPagesList(await pagesStore.getPages(pageParams))
        return pagesStore.pages
      }
      return pagesStore.getPages(pageParams)
    },
    async getPage (id: number, save: boolean = true): Promise<Page> {
      const page = await pagesStore.getPage(id)
      if (save) {
        pagesStore.setCurrentPage(page)
      }
      return page
    },
    removePage (id: number): Promise<Page> {
      return pagesStore.removePage(id)
    },
    editPage (): Promise<Page> {
      return pagesStore.editPage()
    },
    createPage (): Promise<Page> {
      return pagesStore.createPage()
    },
    updateChildren ({ api, id } : { api: 'sites' | 'roles', id: number }): Promise<void> {
      return pagesStore.updateChildren({ api, id })
    }
  }
  return api
}
