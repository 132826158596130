import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { Feeds } from './interfaces'
import { ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'feeds',
  stateFactory: true,
  namespaced: true
})
export default class FeedsModule extends VuexModule {
  /**
   * * Список фидов
   */
  feedsValue: Feeds[] = []

  /**
   * * Текущий фид
   */
  feedValue: Feeds = {
    /** Название фида */
    path: '',
    /** Дата обновления фида */
    updatedAt: null,
    /** Комментарий фида */
    description: ''
  }

  // ? ______________ getters ______________

  get validators (): ValidatorParams {
    return {
      description: [{ required: true, message: 'Введите описание фида', trigger: ['blur'] }]
    }
  }

  /**
   * * Получить текущий фид
   */
  get currentFeed (): Feeds {
    return this.feedValue
  }

  /**
   * * Получить список фидов
   */
  get feeds (): Feeds[] {
    return this.feedsValue
  }

  // ? ______________ setters ______________

  /**
   * * Установить список фидов
   */
  @Mutation
  setFeeds (feeds: Feeds[]) {
    this.feedsValue = feeds
  }

  /**
   * * Установить текущий фид
   */
  @Mutation
  setCurrentFeed (feed: Feeds) {
    this.feedValue = feed
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка фидов
   */
  @Action({
    rawError: true,
    commit: 'setFeeds'
  })
  async getFeeds () {
    try {
      const { data: { data } } = await $axios.get('/feed')
      const response: Feeds[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение фида
   */
  @Action({
    rawError: true
  })
  async updateFeed () {
    try {
      const { data: { data } } = await $axios.post('/feed/update', this.currentFeed)
      const response: Feeds = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на обновление фидов
   * @returns void
   */
  @Action({
    rawError: true
  })
  async updateFeedsAll (): Promise<void> {
    try {
      return await $axios.post('/feed/update-all')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
