import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData } from '../../interfaces'
import { Filters, ProductInput, Product, Params, ValidatorParams } from './interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'products',
  stateFactory: true,
  namespaced: true
})

export default class ProductsModule extends VuexModule {
  /**
   * * Массив всех product исходя из запроса
   */
  products: ResponseData<Product> = defaultData

  /**
   * * Текущая product
   */
  product: Product = {
    title: undefined,
    vendorCode: '',
    sort: 0,
    code: '',
    descr: '',
    active: false,
    deleted: false,
    categoryId: 0,
    categories: [],
    setProductIds: [],
    relatedProducts: [],
    stickerProducts: [],
    manualRelatedProducts: false,
    partner: null,
    options: [],
    discountGroups: [],
    seoTemplateId: null,
    productParams: [],
    manualSort: null,
    typeId: 1,
    roles: []
  }

  //* Параметры товара в разработке
  newParams = {
  // выдано
    issued: Math.floor(Date.now() / 1000),
    // тех.задание
    techSpec: '',
    // Артикул модели
    modalArticle: '',
    // Дизайнер
    designer: '',
    // Модельер
    fashionDesigner: '',
    // Вставки
    inserts: '',
    // Файлы
    files: '',
    // Сдано
    finished: '',
    // Время разработки
    prepared_time: '',
    // Варианты изготовления
    vendor_codes: []
  }

  /**
   * * Фильтры
   */
  filterValues: Filters = {}

  /**
   * * Возможные параметры сортировки по min batch
   */
  sortMinBatch = []

  // ? ______________ getters ______________
  /**
   * * Получить массив products и пагинацией
   */
  get productsList (): ResponseData<Product> {
    return this.products
  }

  /**
   * * Получить фильтры
   */
  get filters (): Filters {
    return this.filterValues
  }

  /**
   * * Получить product из массива products
   */
  get productById () {
    const products = this.products
    return function (id: number): Product | undefined {
      return products.data.find(product => product.id === id)
    }
  }

  /**
   * * Получить текущую product для измения или создания product
   */
  get currentProduct () {
    return this.product
  }

  /**
   * * Получить текущую product для измения или создания product
   */
  get valuesSortMinBatch () {
    return this.sortMinBatch
  }

  get currentNewParams () {
    return this.newParams
  }

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      title: [{ required: true, pattern: validatorsPattern.stringEmpty, message: 'Введите наименование товара', trigger: ['blur'] }],
      code: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите код товара', trigger: ['blur'] }],
      vendorCode: [{ required: true, pattern: validatorsPattern.emptyStringEmpty, message: 'Введите артикул', trigger: ['blur'] }],
      categoryId: [{ required: true, type: 'number', min: 1, pattern: validatorsPattern.naturalNumbers, message: 'Выберите категорию', trigger: ['blur'] }],
      sort: [{ required: true, pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }],
      manualSort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur'] }],
      carousel: {
        productId: [{ required: true, type: 'number', min: 1, pattern: validatorsPattern.naturalNumbers, message: 'Введите название и выберите товар из списка', trigger: ['change'] }]
      }
    }
  }

  // ? ______________ setters ______________
  /**
   * * Установить массив Products
   * @param products массив Products
   */
  @Mutation
  setProductsList (products: ResponseData<Product>) {
    this.products = products
  }

  /**
   * * Обнулить список товаров и пагинатор
   */
  @Mutation
  resetProductsList (): void {
    this.products = defaultData
  }

  /**
   * * Установить значения фильтров
   * @param filters фильтры
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filterValues = filters
  }

  /**
   * * Очистить значения фильтров
   */
  @Mutation
  resetFilters () {
    this.filterValues = {}
  }

  /**
   * * Установить CurrentProduct для измения или создания product
   * @param product текущая Product, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentProduct (product: Product) {
    this.product = product

    if (product.typeId !== 1) {
      for (const field of product.productParams) {
        if (field.name) {
          (this.newParams as Record<string, any>)[field.name] = field.value
        }
      }
    }
  }

  @Mutation
  setCurrentNewParams (newParams: any) {
    this.newParams = newParams
  }

  @Mutation
  setValuesSortMinBatch (newParams: any) {
    this.sortMinBatch = newParams
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentProduct () {
    this.product = {
      title: undefined,
      vendorCode: '',
      sort: 0,
      code: '',
      descr: '',
      active: false,
      deleted: false,
      categoryId: 0,
      setProductIds: [],
      categories: [],
      relatedProducts: [],
      stickerProducts: [],
      manualRelatedProducts: false,
      options: [],
      partner: null,
      discountGroups: [],
      seoTemplateId: null,
      productParams: [],
      manualSort: null,
      typeId: 1,
      roles: []
    }

    this.newParams = {
      issued: Math.floor(Date.now() / 1000),
      techSpec: '',
      modalArticle: '',
      designer: '',
      fashionDesigner: '',
      inserts: '',
      files: '',
      finished: '',
      prepared_time: '',
      vendor_codes: []
    }
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Products по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setProductsList'
  })
  async getProducts (pageParams: ProductInput | null) {
    try {
      const { data } = await $axios.get('/shop/products', { params: { ...this.filterValues, ...pageParams } })
      const response: ResponseData<Product> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить product по id
   * @param id id product, который мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentProduct'
  })
  async getProduct (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/products/${id}`)
      const response: Product = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async findProduct (title: string): Promise<Product[]> {
    try {
      const { data: { data } } = await $axios.get('/shop/products', { params: { title, pageSize: 10 } })
      const response: Product[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение списка товаров по id
   * @param productsIds - id`s продуктов
   * @returns - список продуктов
   */
  @Action({
    rawError: true
  })
  async getProductsById (params: Params): Promise<ResponseData<Product>> {
    try {
      const { data } = await $axios.get('/shop/products', { params })
      const response: ResponseData<Product> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить product
   */
  @Action({
    rawError: true
  })
  async editProduct () {
    try {
      if (this.product.typeId !== 1) {
        for (const field of Object.keys(this.newParams)) {
          const param = this.product.productParams.find(el => el.name === field)

          if (param) {
            param.value = `${(this.newParams as any)?.[field]}`
          }
        }
      }

      const { id, ...product } = this.currentProduct
      const { data: { data } } = await $axios.put(`/shop/products/${id}`, product)
      const response: Product = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать Product
   */
  @Action({
    rawError: true
  })
  async createProduct () {
    try {
      if (this.product.typeId !== 1) {
        for (const field of Object.keys(this.newParams)) {
          if (this.product.productParams.find(param => param.name === 'files') && field === 'files') {
            continue
          } else {
            this.product.productParams.push({ name: field, value: `${(this.newParams as any)?.[field]}` })
          }
        }
      }
      const { data: { data } } = await $axios.post('/shop/products', this.currentProduct)
      const response: Product = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Скачать таблицу товаров в xls
   */
  @Action({
    rawError: true
  })
  async downloadProductsExcel (optionIds: number[]) {
    try {
      const { data } = await $axios.get('/shop/products/excel', {
        params: { ...this.filterValues, optionIds: optionIds.join(',') || undefined },
        responseType: 'blob'
      })
      const response: Product = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить product по id
   * @param id  id product, который мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeProduct (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/products/${id}`)
      const response: Product = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Обновить все products
   */
  @Action({
    rawError: true
  })
  async updateProducts (): Promise<void> {
    try {
      return await $axios.post('/shop/categories/update-products')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на обновление сортировки товаров
   */
  @Action({
    rawError: true
  })
  async updateProductsSort () {
    try {
      return await $axios.post('/shop/products/update-sort')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение excel файла с отчётом по продуктам с нулевой ценой
   */
  @Action({
    rawError: true
  })
  async exportZeroPriceExcel () {
    try {
      const { data } = await $axios.get('/shop/products/zero-price', {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Запрос на получение excel файла с отчётом по продуктам с нулевым весом
   */
    @Action({
      rawError: true
    })
  async exportZeroWeightExcel () {
    try {
      const { data } = await $axios.get('/shop/products/zero-weight', {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Перерасчет цен
   */
  @Action({
    rawError: true
  })
    async recalculatePrices (): Promise<void> {
      try {
        await $axios.post('/shop/products/recalculate-prices')
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

        /**
   * * Запрос на получение возможных параметров сортировки по min batch
   */
        @Action({
          rawError: true,
          commit: 'setValuesSortMinBatch'
        })
  async getValuesSortMinBatch () {
    try {
      const { data } = await $axios.get('/shop/products/min-batch')
      const response = data
      return response.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
