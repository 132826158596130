
import { ResponseData, ValidatorParams } from '../../interfaces'
import { TemplatesPlugin, Template, DropDown, InputParams } from './interfaces'

export default function getPluginApi (templatesStore:any) {
  const api: TemplatesPlugin = {
    // ? ________ getters ________

    get templatesList (): ResponseData<Template> {
      return templatesStore.templatesList
    },

    get validators (): ValidatorParams {
      return templatesStore.validators
    },

    templateById (id: number): Template | undefined {
      return templatesStore.templateById(id)
    },

    get currentTemplate (): Template {
      return templatesStore.currentTemplate
    },

    get dropDowns (): DropDown[] {
      return templatesStore.dropDowns
    },

    // ? ________ setters ________

    set dropDowns (dropDownList: DropDown[]) {
      templatesStore.setDropDowns(dropDownList)
    },

    set templatesList (templates: ResponseData<Template>) {
      templatesStore.setTemplatesList(templates)
    },

    resetCurrentTemplate () {
      templatesStore.resetCurrentTemplate()
    },

    set currentTemplate (template: Template) {
      templatesStore.setCurrentTemplate(template)
    },

    resetTemplates () {
      templatesStore.resetTemplates()
    },

    resetDropDowns () {
      templatesStore.resetDropDowns()
    },

    // ? ________ actions ________
    getDropDowns (): Promise<DropDown[]> {
      return templatesStore.getDropDowns()
    },

    getTemplates (inputParams: InputParams | null = null): Promise<ResponseData<Template>> {
      return templatesStore.getTemplates(inputParams)
    },

    getTemplate (id: number): Promise<Template> {
      return templatesStore.getTemplate(id)
    },

    removeTemplate (): Promise<Template> {
      return templatesStore.removeTemplate()
    },

    editTemplate (): Promise<Template> {
      return templatesStore.editTemplate()
    },

    createTemplate (): Promise<Template> {
      return templatesStore.createTemplate()
    },

    syncTemplates (): Promise<boolean> {
      return templatesStore.syncTemplates()
    }

  }
  return api
}
