import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ResponseData } from '../../interfaces'
import { Bill, BillsInput } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'bills',
  stateFactory: true,
  namespaced: true
})

export default class BIllsModule extends VuexModule {
    bills: ResponseData<Bill> = defaultData

    /**
   * * Текущий bill
   */
    bill: Bill = {
      id: 0,
      guid: '',
      number: '',
      date: 0,
      amount: 0,
      orders: [],
      orderId: 0,
      contragentId: '',
      params: {},
      requisites: {},
      products: [],
      createdAt: 0,
      updatedAt: 0
    }

    billQr = ''

    filtersState = {
      page: 1,
      pageSize: 20,
      orderId: undefined,
      sort: undefined,
      order: undefined
    } as BillsInput

    // ? ______________ getters ______________
    /**
   * * Получить массив bills и пагинацией
   */
    get billsList (): ResponseData<Bill> {
      return this.bills
    }

    /**
   * * Получить текущую bill
   */
    get currentBill (): Bill {
      return this.bill
    }

    /**
   * * Получить текущую billQr
   */
    get currentBillQr (): any {
      return this.billQr
    }

    get filters () {
      return this.filtersState
    }

    // ? ______________ setters ______________
  /**
   * * Установить массив Bills
   * @param bills массив bills
   */
  @Mutation
    setBillsList (bills: ResponseData<Bill>) {
      this.bills = bills
    }

  /**
   * * Установить CurrentBills для измения или создания bills
   * @param bill текущая Bills, которую мы изменяем или создаем
   */
  @Mutation
  setCurrenBill (bill: Bill) {
    this.bill = bill
  }

    /**
   * * Установить CurrentBills для измения или создания bills
   * @param bill текущая Bills, которую мы изменяем или создаем
   */
    @Mutation
  setCurrenBillQr (billQr: any) {
    this.billQr = billQr
  }

    @Mutation
    setFilters (filters: BillsInput | null) {
      if (!filters) {
        this.filtersState = {
          page: 1,
          pageSize: 20,
          orderId: undefined,
          sort: undefined,
          order: undefined
        }

        return
      }
      this.filtersState = filters
    }

    // ? ______________________________________actions______________________________________

  /**
   * * Получить список Products по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setBillsList'
  })
    async getBills (pageParams: BillsInput | null) {
      try {
        const { data } = await $axios.get('/voznes/bill', { params: { ...pageParams } })
        const response: ResponseData<Bill> = data
        this.setFilters(pageParams)
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

  /**
   * * Получить product по id
   * @param id id product, который мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrenBill'
  })
  async getBill (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/voznes/bill/${id}`)
      const response: Bill = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Получить qr code
   * @param id id product, который мы хотим получить
   */
    @Action({
      rawError: true,
      commit: 'setCurrenBillQr'
    })
  async getBillQr (id: number) {
    try {
      const data = await $axios.get(`/voznes/bill/${id}/qr`, { responseType: 'blob' })
      const response: any = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
