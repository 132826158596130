import { Filters, OrderBill, Bill, OrderBillsPlugin, Params, BillRequestData } from './interfaces'
import { ResponseData, ValidatorParams } from '~/store/interfaces'

export default function gePluginApi (orderBillsStore:any) {
  const api: OrderBillsPlugin = {
    // ? ------ getters ------ //

    get filters (): Filters {
      return orderBillsStore.filters
    },

    get orderBill (): OrderBill {
      return orderBillsStore.orderBill
    },

    get orderBills (): ResponseData<Bill> {
      return orderBillsStore.orderBills
    },

    get validators (): ValidatorParams {
      return orderBillsStore.validators
    },

    // ? ------ setters ------ //

    set filters (filters: Filters) {
      orderBillsStore.setFilters(filters)
    },

    resetFilters (): void {
      orderBillsStore.resetFilters()
    },

    set orderBill (orderBill: OrderBill) {
      orderBillsStore.setOrderBill(orderBill)
    },

    resetOrderBill (): void {
      orderBillsStore.resetOrderBill()
    },

    set orderBills (orderBills: ResponseData<Bill>) {
      orderBillsStore.setOrderBills(orderBills)
    },

    resetOrderBills (): void {
      orderBillsStore.resetOrderBills()
    },

    // ? ------ actions ------ //

    getOrderBills (params: Params): Promise<ResponseData<Bill>> {
      return orderBillsStore.getOrderBills(params)
    },

    createOrderBill (orderId: number, billRequestData: BillRequestData | undefined): Promise<OrderBill> {
      return orderBillsStore.createOrderBill({ orderId, billRequestData })
    },

    addOrderBillDoc (orderId: number): Promise<OrderBill> {
      return orderBillsStore.addOrderBillDoc(orderId)
    },

    changeOrderBillStatus (params : {id: number, orderId: number, status: number}): Promise<OrderBill> {
      return orderBillsStore.changeOrderBillStatus(params)
    }
  }

  return api
}
